import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "../../validation/is-empty";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import GameBox from "./GameBox";
import { getGames } from "../../actions/gameActions";

class GamesPage extends Component {
  componentDidMount() {
    this.props.getGames();

    window.scrollTo(0, 0);
  }

  render() {
    const { games } = this.props;
    const page = this.props.match.params.page;
    let boxContent, pageCnt, pageLink = [];
    if (games.length === 0) {
      boxContent = null;
      pageCnt = 1;
    } else {
      let tempGames = games.filter(game => !isEmpty(game.site));
      let gamesLength = tempGames.length;
      pageCnt = Math.ceil(gamesLength / 5);

      let cnt = (parseInt(page) - 1) * 5;
      if (cnt > gamesLength - 1) {
        this.props.history.push('/game/gamesroom/1');
      }

      if (tempGames.length === 0) {
        boxContent = null;
      } else {
        boxContent = tempGames
          .map((game, idx) => idx>=cnt && idx<cnt+5 && <GameBox key={game.game_id} game={game} />);
      }

      for(let i=1;i<=pageCnt;i++) {
        if (parseInt(page) === i){
          pageLink.push(<li className="active"><a href={`/game/gamesroom/${i}`}>{i}</a></li>);
        } else {
          pageLink.push(<li><a href={`/game/gamesroom/${i}`}>{i}</a></li>);
        }
      }
    }

    return (
      <div id="page">
        <Navbar active={3} />        
        <aside className="colorlib-breadcrumbs">
          <div className="container">
          <div className="row">
            <div className="col-md-12 breadcrumbs text-center">
            <h2>所有遊戲</h2>
            <p><span>All GAMES</span></p>
            </div>
          </div>
          </div>
        </aside>
        <div id="colorlib-container">
          <div className="container">
          <div className="row">
            <div className="col-md-12">
            {boxContent}
            <div className="row">
              <div className="col-md-12 text-center">
              <ul className="pagination">
                <li className={parseInt(page)===1?"disabled":""}><a href="/game/gamesroom/1">&laquo;</a></li>
                {pageLink}
                <li className={parseInt(page)===pageCnt?"disabled":""}><a href={`/game/gamesroom/${pageCnt}`}>&raquo;</a></li>
              </ul>
              </div>
            </div>
            </div>
          </div>
          </div>
        </div>
        <Footer active={3} />
      </div>
    );
  }
}

GamesPage.propTypes = {
  getGames: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  games: state.games.games
});

export default connect(mapStateToProps, { getGames })(GamesPage);