import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import * as moment from "moment";
import Moment from "react-moment";
import $ from 'jquery';

class Footer extends Component {

  componentDidMount() {
    this.goToTop();
  }

  goToTop = function() {

		$('.js-gotop').on('click', function(event){
			
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $('html').offset().top
			}, 500);
			
			return false;
		});

		$(window).scroll(function(){

			var $win = $(window);
			if ($win.scrollTop() > 200) {
				$('.js-top').addClass('active');
			} else {
				$('.js-top').removeClass('active');
			}

		});
	
	};

  render() {
    const { active } = this.props;
    let activeArray = ["","","","",""];
    activeArray[active] = "active";
    
    return (
      <Fragment>
        <div className="footer-area row">
          <div className="container">
          <div className="row">
            <div className="col-sm-4 footer-menu-box">
            <div className="seat"> <Link to="/game"><img src="/game/img/e_3.png" alt="" /></Link> </div>
            <p>「龍邑」成立於2014年，由台灣一群熱愛遊戲產業菁英所創立，憑藉著熱情與夢想以及對遊戲的滿腔熱忱，戮力提供最精緻創新的遊戲予玩家，目標成為亞洲數位娛樂的領導品牌。</p>
            </div>
            <div className="col-sm-3 footer-menu-box menu">
            <div className="seat">
              <h4>&ensp;選單</h4>
            </div>
            <p className={activeArray[1]}>{active===1 ? "首頁" : <Link to="/game">首頁</Link>}</p>
            <p className={activeArray[2]}>{active===2 ? "關於龍邑" : <Link to="/game/about">關於龍邑</Link>}</p>
            <p className={activeArray[3]}>{active===3 ? "所有遊戲" : <Link to="/game/gamesroom/1">所有遊戲</Link>}</p>
            <p className={activeArray[4]}>{active===4 ? "客服中心" : <Link to="/game/support">客服中心</Link>}</p>
            </div>
            <div className="col-sm-5 footer-menu-box menu">
            <div className="seat">
              <h4>&ensp;公司資訊</h4>
            </div>
            <p><i className="icon-share"></i>&ensp;傳真：02-22188229</p>
            <p><i className="icon-location"></i>&ensp;新北市新店區民權路95號7樓之3</p>
            </div>
          </div>
          </div>
        </div>
        <div className="container">
          <footer className="row">
          <div className="col-sm-6 classynav">
            <ul>
            <li><Link to="/game/complete_agreement#service">會員服務條款</Link>　|</li>
            <li><Link to="/game/complete_agreement#data_use">個資同意書</Link>　|</li>
            <li><Link to="/game/complete_agreement#privacy">隱私權政策</Link></li>
            </ul>
          </div>
          <div className="col-sm-6 copyright">Long E Co., Ltd © <Moment format="YYYY">{moment()}</Moment> Copyrights Reserved.<i className="fa fa-heart-o" aria-hidden="true"></i> </div>
          </footer>
        </div>
        <div className="gototop js-top"> <Link to="#" className="js-gotop"><i className="icon-arrow-up2"></i></Link> </div>
      </Fragment>
    );
    }
}

Footer.propTypes = {
  active: PropTypes.number.isRequired,
};

export default Footer;
