import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class Navbar extends Component {
  render() {
    const { active } = this.props;
    let activeArray = ["","","","",""];
    activeArray[active] = "active";

    return (
      <nav className="colorlib-nav" role="navigation">
        <div className="top-menu">
          <div className="container">
            <div className="row">
            <div className="col-xs-2">
            <div className="col-xs-2"> <Link to="/game"><img src="/game/img/e_3.png" width="90px" alt="" /></Link> </div>
            </div>
            <div className="col-xs-10 text-right menu-1">
              <ul>
              <li className={activeArray[1]}><Link to="/game">首頁</Link></li>
              <li className={activeArray[2]}><Link to="/game/about">關於龍邑</Link></li>
              <li className={activeArray[3]}><Link to="/game/gamesroom/1">所有遊戲</Link></li>
              <li className={activeArray[4]}><Link to="/game/support">客服中心</Link></li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  active: PropTypes.number.isRequired,
};

export default Navbar;
