import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import gameReducer from "./gameReducer";
import aboutReducer from "./aboutReducer";

export default combineReducers({
  errors: errorReducer,
  games: gameReducer,
  about: aboutReducer,
});
