import React, { Component } from "react";
import { Link } from "react-router-dom";

class CSBox extends Component {
  render() {
    const { game } = this.props;
    return (
      <Link to={`/service_quick?param_game_id=${game.game_id}`} target="_blank" rel="noopener noreferrer">
        <div className="col-md-4 box"> <img src={game.logo_path} alt="" /> <span>{game.game_name} 
        {game.new_time && new Date(game.new_time)>new Date() && <span class="new">NEW</span>}</span> </div>
      </Link>
    );
  }
}

export default CSBox;
