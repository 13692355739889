import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { contactUsEmail } from "../../actions/aboutActions";
import './about.css';

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
      errors: "",
      
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    //console.log(e.target.name, e.target.value);
  }

  onClick(e) {
    e.preventDefault();
    
    let emailData = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      phone: this.state.phone,
      message: this.state.message,
    };

    this.props.contactUsEmail(emailData);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });

      if (this.props.errors.status && this.props.errors.status !== 1){
        alert("發送失敗，請稍候再試，謝謝!");
      }
      
      if (this.props.errors.content){
        alert(this.props.errors.content);
      }
    }

    if (this.props.create_result && this.props.create_result !== prevProps.create_result && this.props.create_result.status === 1){
      alert("發送成功，期待有機會與您展開合作。");
    }
  }

  render() {
    return (
      <div id="page">
        <Navbar active={2} />    
        <aside className="colorlib-breadcrumbs">
          <div className="container">
          <div className="row">
            <div className="col-md-12 breadcrumbs text-center">
            <h2>關於龍邑</h2>
            <p><span>About LongE</span></p>
            </div>
          </div>
          </div>
        </aside>

        <div id="colorlib-container">
          <div className="container">
          <div className="row">
            <div className="col-md-3 logo"><img src="/game/img/logo_about.png" alt="" /></div>
            <div className="col-md-9">
            <p>「龍邑」成立於2014年，由台灣一群熱愛遊戲產業菁英所創立，憑藉著熱情與夢想以及對遊戲的滿腔熱忱，戮力提供最精緻創新的遊戲予玩家，目標成為亞洲數位娛樂的領導品牌。</p>
            <blockquote><strong><span>未來，龍邑將持續提供有趣、多元的數位娛樂內容，滿足玩家對數位娛樂的渴望。</span></strong></blockquote>
            <p className="first-letra">龍邑不僅引進最優質的遊戲，更針對在地玩家特有的喜好，打造出最佳的區域化版本，並提供最誠摯用心的服務，讓所有熱愛遊戲的玩家，能夠在「龍邑」的遊戲營運下，盡情享受愉悅歡樂的遊戲體驗。</p>
            </div>
          </div>
          </div>
        </div>

        <div className="recru">
          <div className="container fix">
          <div className="row">
            <div className="col-md-12">
            <h3 style={{color: '#fff'}}>龍邑遊戲徵才<br />
              <p style={{fontSize: '1.8rem',lineHeight: '60px'}}>滿懷熱忱、熱愛遊戲的你，歡迎加入我們的行列！</p></h3>
            <div className="col-md-12 text-center"><a href="https://www.104.com.tw/company/1a2x6bj25q" className="btns" target="_blank" style={{marginTop: '0'}} rel="noopener noreferrer"><i className="icon-forward"></i> 立即前往</a></div>
            </div>
          </div>
          </div>
        </div>

        <div className="section-container">
          <div className="container contact_us">
          <div className="row">
            <div className="col-sm-12">
            <h3><strong>聯絡龍邑</strong></h3>
            <form className="contact_us row" id="contactForm" novalidate="novalidate">
              <div className="form-group col-sm-12 text">
              <p>期待與同樣熱愛遊戲的品牌，創造嶄新的跨領域合作！<br />歡迎聯絡我們</p>
              </div>
              <div className="form-group col-sm-12">
              <input type="text" value={this.state.name} onChange={this.onChange} id="name" name="name" placeholder="姓名" 
              className={classnames("form-control", { "is-invalid": this.state.errors.name })} />
              {this.state.errors.name && <div className="invalid-feedback">{this.state.errors.name}</div>}
              </div>
              <div className="form-group col-sm-12">
              <input type="email" value={this.state.email} onChange={this.onChange} id="email" name="email" placeholder="E-mail"
              className={classnames("form-control", { "is-invalid": this.state.errors.email })} />
              {this.state.errors.email && <div className="invalid-feedback">{this.state.errors.email}</div>}
              </div>
              <div className="form-group col-sm-12">
              <input type="text" value={this.state.subject} onChange={this.onChange} id="subject" name="subject" placeholder="公司"
              className={classnames("form-control", { "is-invalid": this.state.errors.subject })} />
              {this.state.errors.subject && <div className="invalid-feedback">{this.state.errors.subject}</div>}
              </div>
              <div className="form-group col-sm-12">
              <input type="text" value={this.state.phone} onChange={this.onChange} id="phone" name="phone" placeholder="電話"
              className={classnames("form-control", { "is-invalid": this.state.errors.phone })} />
              {this.state.errors.phone && <div className="invalid-feedback">{this.state.errors.phone}</div>}
              </div>
              <div className="form-group col-sm-12">
              <textarea value={this.state.message} onChange={this.onChange} name="message" id="message" rows="2" placeholder="訊息"
              className={classnames("form-control", { "is-invalid": this.state.errors.message })}></textarea>
              {this.state.errors.message && <div className="invalid-feedback">{this.state.errors.message}</div>}
              </div>
              <div className="col-md-12 text-center"><a href="#" className="btns" onClick={this.onClick}><i className="icon-forward"></i> 提交</a></div>
            </form>
            </div>
          </div>
          </div>
          <Footer active={2} />
        </div>
      </div>
    );
  }
}

AboutPage.propTypes = {
  contactUsEmail: PropTypes.func.isRequired,
  errors: PropTypes.object,
};
const mapStateToProps = (state) => ({
  create_result: state.about.create_result,
  errors: state.errors,
});
export default connect(mapStateToProps, { contactUsEmail })(AboutPage);