import {
  GET_ERRORS,
  CLEAR_ERRORS,
  POSTING_LOADING,
  CLEAR_LOADING,
  CONTACT_US_EMAIL,
} from './types';

import axios from 'axios';

export const postLoading = () => {
  return {
    type: POSTING_LOADING,
  };
};
export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

export const contactUsEmail = (emailData) => (dispatch) => {
  dispatch(postLoading());
  axios
    .post('/api/about/contact_us', emailData)
    .then((res) => {
      dispatch({
        type: CONTACT_US_EMAIL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
