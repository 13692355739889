import React, { Component } from "react";
import isEmpty from "../../validation/is-empty";

class GameBox extends Component {
  render() {
    const { game } = this.props;
    return (
      <div className="gamelist-single">
        <div className="row">
        <div className="col-lg-4 mb-3">
          <figure> <img src={game.game_bg_path} alt="" className="img-fluid" />
          <figcaption> <img src={game.game_title_path} alt="" className="img-fluid" /> </figcaption>
          </figure>
        </div>
        <div className="col-lg-8 gamelist-details">
          <h3> {game.game_name}</h3>
          <p className="text-justify"> {game.introduction}</p>
          <div className="row gamelistbtn">
          <div className="col-12 col-sm-3 mb-3"> <a href={game.site} className="btns" target="_blank" rel="noopener noreferrer"><i className="icon-forward"></i> 前往官網</a> </div>
          {!isEmpty(game.fanpage) && <div className="col-12 col-sm-3 mb-3"> <a href={game.fanpage} className="btns" target="_blank" rel="noopener noreferrer"><i className="icon-forward"></i> FACEBOOK</a> </div>}
          {!isEmpty(game.youtube) && <div className="col-12 col-sm-3 mb-3"> <a href={game.youtube} className="btns" target="_blank" rel="noopener noreferrer"><i className="icon-forward"></i> YOUTUBE</a> </div>}
          <div className="col-12 col-sm-3 mb-3"> <a href={`/service_quick?param_game_id=${game.game_id}`} className="btns" target="_blank" rel="noopener noreferrer"><i className="icon-forward"></i> 客服中心</a> </div>
          </div>
          <div className="row gamelistplat">
          <div className="col-12 mb-3"> 
          {!isEmpty(game.appstore) && <a className="app-store" target="_blank" href={game.appstore} rel="noopener noreferrer"><img src="/game/img/appstore.svg" alt="" /> </a>} 
          {!isEmpty(game.googleplay) && <a className="app-store" target="_blank" href={game.googleplay} rel="noopener noreferrer"><img src="/game/img/googleplay.svg" alt="" /></a>} 
          {!isEmpty(game.windows) && <a className="app-store windowsicon" target="_blank" href={game.windows} rel="noopener noreferrer"><img src="/game/img/windows.svg" alt="" /></a>}
          </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default GameBox;
