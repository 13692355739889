import React, { Component } from "react";

class TopGameBox extends Component {
  render() {
    const { game } = this.props;
    return (
      <div className="col-md-4">
        <div className="blog-entry">
          <div className="blog-img gamelist-single"> <a href={game.site} target="_blank" rel="noopener noreferrer">
          <figure> <img src={game.top_game_bg_path} alt="" className="img-fluid" />
            <figcaption> <img src={game.top_game_title_path} alt="" className="img-fluid" /> </figcaption>
          </figure>
          </a> </div>
        </div>
      </div>
    );
  }
}

export default TopGameBox;
