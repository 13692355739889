import React, { Component } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  //Redirect,
  Switch,
} from "react-router-dom";
import store from "./store";
//import "./App.css";

import HomePage from "./components/home/HomePage";
import CompleteAgreement from "./components/policies/CompleteAgreement";
import AboutPage from "./components/about/AboutPage";
import GamesPage from "./components/gamesroom/GamesPage";
import SupportPage from "./components/support/SupportPage";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Switch>
             
              <Route exact path="/game" component={HomePage} />
              <Route exact path="/game/complete_agreement" component={CompleteAgreement} />
              <Route exact path="/game/about" component={AboutPage} />
              <Route exact path="/game/gamesroom/:page" component={GamesPage} />              
              <Route exact path="/game/support" component={SupportPage} />

            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
