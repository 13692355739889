import React, { Component } from "react";

class CarouselBox extends Component {
  render() {
    const { game } = this.props;
    return (
      <li key={`key-${game.game_id}`} style={{backgroundImage: `url(${game.carousel_path})`}}> 
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
          <div className="col-md-6 col-md-pull-3 col-sm-12 col-xs-12 col-md-offset-3 slider-text">
            <div className="slider-text-inner">
            <div className="desc">
              <p className="meta"><span className="date">{game.slogan}</span> </p>
              <h1>{game.game_name}</h1>
              <p className="meta"><a href={game.site}><span className="cat">前往官網</span></a></p>
            </div>
            </div>
          </div>
          </div>
        </div>
      </li>
    );
  }
}

export default CarouselBox;
