export const GET_CONFIG = "GET_CONFIG";

export const GET_GAMES = 'GET_GAMES';
export const GET_CAROUSELS = 'GET_CAROUSELS';
export const GET_TOPGAMES = 'GET_TOPGAMES';
export const GET_GAME = 'GET_GAME';
export const GET_SERVERS = 'GET_SERVERS';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const POSTING_LOADING = 'POSTING_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const BEGIN_LOADING = 'BEGIN_LOADING';

export const CONTACT_US_EMAIL = 'CONTACT_US_EMAIL';