import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "../../validation/is-empty";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import CSBox from "./CSBox";
import { getGames } from "../../actions/gameActions";
import './support.css';

class SupportPage extends Component {
  componentDidMount() {
    this.props.getGames();

    window.scrollTo(0, 0);
  }

  render() {
    const { games } = this.props;
    let boxContent;
    if (games.length === 0) {
      boxContent = null;
    } else {
      boxContent = games
        .filter(game => !isEmpty(game.site))
        .map(game => <CSBox key={game.game_id} game={game} />);
    }

    return (
      <div id="page">
        <Navbar active={4} />        
        <aside className="colorlib-breadcrumbs">
          <div className="container">
          <div className="row">
            <div className="col-md-12 breadcrumbs text-center">
            <h2>客服中心</h2>
            <p><span>Customer Service</span></p>
            </div>
          </div>
          </div>
        </aside>
        <div className="colorlib">
          <div className="container distance">
          <div className="row"> {boxContent}</div>
          </div>
        </div>
        <Footer active={4} />
      </div>
    );
  }
}

SupportPage.propTypes = {
  getGames: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  games: state.games.games
});

export default connect(mapStateToProps, { getGames })(SupportPage);