import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

import './policy.css';

class CompleteAgreement extends Component {
  componentDidMount() {
    let url = window.location.hash;
    let anchor = url.indexOf("#") >= 0 ? url.substring(url.indexOf("#")+1) : "";
    if (anchor)
    {
      let anchorElement = document.getElementById(anchor);
      if (anchorElement) {
        window.scrollTo(0, anchorElement.offsetTop);
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Fragment>
        <div id="page">
          <Navbar active={0} />
          <aside className="colorlib-breadcrumbs">
            <div className="container">
            <div className="row">
              <div className="col-md-12 breadcrumbs text-center">
              <h2>會員服務暨個資隱私權同意書</h2>
              <p><span>Member Service and Personal Data Privacy Consent</span></p>
              </div>
            </div>
            </div>
          </aside>
          <div className="content-terms">
            <div className="container">
            <p className="info">歡迎您加入會員並使用龍邑股份有限公司（下稱龍邑）所提供之各項會員服務。此份同意書包含 <a href='#service'><span>【龍邑會員服務同意書】</span></a>， <a href='#data_use'><span>【個人資料提供同意書】</span></a>， <a href='#privacy'><span>【隱私權政策】</span></a> 為保障您的權益，請詳細閱讀本同意書所有內容，您同意當您在線上點選「同意」鍵時，即視為您已經詳細閱讀、了解並願意遵守本服務同意書、注意事項之所有約定。 <br />
            </p>
            </div>
          </div>
          <aside id="service" className="colorlib-breadcrumbs">
            <div className="container">
            <div className="row">
              <div className="col-md-12 breadcrumbs text-center">
              <h2>龍邑會員服務同意書</h2>
              <p><span>LongE Membership Service Agreement</span></p>
              </div>
            </div>
            </div>
          </aside>
          <div className="content-terms">
            <div className="container">
            <p className="info"> 歡迎您加入龍邑會員並使用龍邑股份有限公司（下稱龍邑）所提供之各項會員服務。為保障您的權益，請詳細閱讀本服務同意書所有內容，<span>提醒您</span>：如果您是未成年人（未滿十八歲），或為限制行為能力人者或受輔助宣告之人，您必須告知法定代理人（如：父母、監護人、輔助人）使其詳閱、瞭解本服務同意書內容，並取得法定代理人（如：父母、監護人、輔助人）之允許始得進行。<span>提醒您</span>，要先行審閱本契約三日以上，再按「我同意」。 <br />
              <br />
              一、當事人及其基本資料 <br />
              (一) 消費者(請依會員註冊流程填寫) <br />
              (二) 企業經營者：龍邑股份有限公司 <br />
              代表人：尤佑文 <br />
              統一編號：54700022 <br />
              電話：(02)2218-8185 <br />
              地址：新北市新店區民權路95號7樓之3 <br />
              網址：https://game.longeplay.com.tw/game/support <br />
              電子郵件：longesupport@longeplay.com.tw <br />
              <br />
              二、法定代理人 <br />
              若消費者為限制行為能力人者或受輔助宣告之人，本契約訂定時，應經消費者之法定代理人、監護人或輔助人閱讀、了解並同意本契約之所有內容，本契約始生效力；若您為無行為能力人者，本契約之訂定，則應由您的法定代理人或監護人代為意思表示。 <br />
              若有限制行為能力人未經同意或無行為能力人或受輔助宣告之人未由法定代理人、監護人或輔助人代為付費購買點數致生法定代理人、監護人或輔助人主張退費時，法定代理人、監護人或輔助人得依本會員同意書流程，備妥證明文件並提出申請，經本公司確認後，本公司扣除贈送點數、商品或其他服務(例如：虛擬貨幣或寶物、進階道具等)後，依第十八條條款來進行計算並退還您剩餘未使用之遊戲費用。 <br />
              若消費者為限制行為能力人或無行為能力人或受輔助宣告之人，除應符合第一項規定外，並應於消費者之法定代理人、監護人或輔助人閱讀、瞭解並同意本會員同意書之所有內容後，方得使用本遊戲服務，本契約變更時亦同。 <br />
              <br />
              三、遵守會員規範及法律規定 <br />
              您了解您註冊成為會員後，即可使用龍邑所提供之各項服務(以下稱本服務)。當會員使用本服務時，除了同意遵守本服務同意書外，亦接受龍邑其他針對龍邑會員之相關規範（包括但不限於客服中心內容、管理規則、公告及注意事項等）及相關法令之規定。 <br />
              <br />
              四、服務簡介 <br />
              （一）接受本服務之會員必須自行配備上網所需之各項電腦設備，並負擔包括但不限於「網際網路連線費用」、「電話費用」等之電信相關費用。 <br />
              （二）當您完成相關註冊程序後，即可具有龍邑會員資格，並可開始使用本服務，包括但不限於使用線上遊戲、異業合作、金流儲值服務等。您同意當您成為龍邑會員後使用之各項會員服務，其各項服務內容之所有權仍屬龍邑所有，會員僅得依本服務同意書之約定使用，不得出租、出借、移轉、私下交易買賣或讓與給其他第三人使用。 <br />
              （三）為增進會員權益、擴充服務內容，龍邑將致力於提供會員各項新穎之服務或功能，您同意本會員服務同意書適用於現有或將來龍邑所提供龍邑會員帳號得使用之各項服務。 <br />
              （四）金流付費服務：為保障您的交易安全，請使用合法、正當之方式進行相關服務儲值，若您以冒名、詐騙或其他虛偽不正之方式進行「儲值」或請他人代為進行「儲值」或使用其他相關服務，龍邑將依照遊戲管理規則處理，若涉及民事及刑事則交由權利所屬法院審理。 <br />
              （五）您可以於開始遊戲後七日內，以書面告知龍邑解除本契約。針對未使用之付費購買點數提出相關退費申請。 <br />
              經本公司確認後，本公司將扣除贈送點數、商品或其他服務(例如：虛擬貨幣或寶物、進階道具等)後，退還您剩餘未使用之遊戲費用。 <br />
              <br />
              五、真實登錄義務 <br />
              基於龍邑所提供之各項服務，您同意於註冊時依註冊申請程序所提示之項目，登錄您本人正確、真實及完整之個人資料；當您的個人資料有異動時，請立即更新，以維持您個人資料之正確、真實及完整。如因您登錄不實資料或冒用他人名義以致於侵害他人之權益或違法時，應自負法律責任。 <br />
              為確保會員使用本服務之權益，當無法驗證會員身份時，會員須提供身分證明、交易證明等資料，若缺一則無法驗證通過。若您為限制能力行為人或無行為能力人、受輔助宣告之人，簽定本契約應由您的法定代理人、監護人或輔助人同意後簽署生效。若有限制行為能力人未經同意或無行為能力人、受輔助宣告之人未由法定代理人、監護人或輔助人代為付費購買點數致生法定代理人、監護人或輔助人主張退費時，需備妥證明文件並提出申請，經確認後進行後續申訴事宜。 <br />
              <br />
              六、會員規範之增訂及修改 <br />
              本公司修改本契約時，應於官網首頁或遊戲登入頁面公告之，並依您登錄之通訊資料通知您。 <br />
              本公司未依前項進行公告及通知者，其契約之變更無效。 <br />
              您於第一項通知到達後十五日內： <br />
              (一) 未為反對之表示者，本公司依契約變更後之內容繼續提供本遊戲服務。 <br />
              (二) 以書面或電子郵件為反對之表示者，依第十八條契約終止方式處理。 <br />
              <br />
              七、計費標準、變更及其通知相關規定 <br />
              本遊戲服務之收費計算方式免費制，遊戲分級、付款方式及商品或其他服務，龍邑應在官網首頁、遊戲登入頁面或購買頁面公告載明付款方式及商品資訊。；遊戲服務內（例如：遊戲商城、線上商店等）有提供需消費者額外付費購買之點數、商品或其他服務（例如：虛擬貨幣或寶物、進階道具等）需額外付費取得；機率性抽獎內容則為付費後取得抽獎機會，消費者購買或參與活動不代表即可獲得特定商品。 <br />
              遊戲服務所需之低硬體需求請見遊戲官網 <br />
              費率如有調整時（不包含通路費用調整因素等非龍邑造成之因素），自調整生效日前30日公告；若新費率高於舊費率時，您在新費率生效日前已於遊戲中登錄之付費購買點數或遊戲費用應依舊費率計收。 <br />
              <br />
              八、服務之停止與更改 <br />
              （一）您完成註冊程序後取得之帳號及密碼，僅供您個人使用。 <br />
              密碼得依系統提供之修改機制進行變更。系統持有者不會主動詢問消費者之密碼、並應在契約終止後六十日內，保留您附隨於該帳號之電磁紀錄。 <br />
              （二）契約非因可歸責甲方之事由而終止者，甲方於前項期間內辦理續用後有權繼續使用帳號及附隨於該帳號之電磁紀錄。 <br />
              （三）上述期間屆滿時，用戶仍未辦理續用，龍邑得刪除該帳號及附隨於該帳號之所有資料，但法令另有規定者不在此限 <br />
              （四）使用他人的名義申請本服務。 <br />
              （五）違反本服務條款時。 <br />
              （六）其他經龍邑判定有違公平、合理方式使用本服務者。 <br />
              （七）會員使用本服務之行為有造成龍邑受有損失或損害之虞者。 <br />
              <br />
              九、服務暫停或中斷 <br />
              （一）龍邑為維護本遊戲服務相關系統及軟硬體設備而預先規劃暫停本遊戲服務之全部或一部時，應於七日前於官網首頁、遊戲登入頁面或購買頁面公告。但因臨時性、急迫性或不可歸責於乙方之事由者，不在此限。 <br />
              因可歸責龍邑事由，致您不能連線使用本遊戲服務時，應立即更正或修復。對於您於無法使用期間遭扣除遊戲費用或遊戲內商品，龍邑應返還遊戲費用或商品，無法返還時則應提供其他合理之補償。 <br />
              （二）如因龍邑對於本網站相關系統設備進行遷移、更換或維護而必須暫停或中斷全部或一部之服務時，龍邑於暫停或中斷服務前將於本公司官方網站上公告或以電子郵件進行通知。 <br />
              （三）請於平時對電腦設備採取適當之安全防護措施，以避免本服務之暫停、中斷可能造成您使用上之不便、資料遺失或其他經濟及時間上之損失，用以保障您的相關權益。 <br />
              <br />
              十、會員義務與責任 <br />
              （一）會員不得利用龍邑會員服務進行任何商業、違法、不當行為。 <br />
              （二）除遵守本服務同意書之約定外，您同意遵守龍邑之各項服務之營業規章、管理規則，並同意不從事下述所列之行為。若消費者被判定有以下行為發生時，龍邑依消費者登錄之通訊資料通知消費者後得立即終止本契約、請求因此所生之損害賠償： <br />
              1.利用任何系統或工具，意圖破壞入侵龍邑伺服器及官方網站，電腦系統之惡意攻擊。 <br />
              2.使用外掛程式、利用程式漏洞、或其他違反遊戲常態設定、修改遊戲、侵入會員資料庫、散播電腦病毒程式及竊取、更改、破壞或他人資訊、公平合理之方式進行遊戲情事等之行為者。 <br />
              3.以冒名詐騙或其他虛偽不正等方式付費購買點數或遊戲內商品。 <br />
              4.因同一事由違反遊戲管理規則達一定次數(不得少於三次)以上，經依會員同意書(第十之三項)通知改善而未改善者。 <br />
              5.經司法機關查獲從事任何不法行為者。 <br />
              6.龍邑對前項事實認定產生錯誤或無法舉證時，龍邑應對消費者之損害負賠償責任。 <br />
              7.契約終止時，龍邑依會員同意書第十八項服務終止與退費內計算方式，並於三十日內退還消費者未使用之付費購買之點數，或依雙方同意之方式處理前述點數或費用。 <br />
              （三）違反遊戲管理規則之處理，若有事實足證用戶於本服務違反下述規則時，龍邑應於官網、遊戲登入頁或購買頁公告，並依照用戶登錄之通訊資料通知用戶。 <br />
              為規範遊戲進行之方式，龍邑應訂立合理公平之遊戲管理規則，消費者應遵守龍邑公告之遊戲管理規則。 <br />
              遊戲管理規則之變更應依「網路連線遊戲服務定型化契約應記載及不得記載事項」第17點程序為之。 <br />
              遊戲管理規則有下列情形之一者，其規定無效： <br />
              A牴觸本契約之規定。 <br />
              B.剝奪或限制消費者之契約上權利。但龍邑依「網路連線遊戲服務定型化契約應記載及不得記載事項」第15點之規定處理者，不在此限。 <br />
              然用戶第一次違反管理規則時，龍邑將通知消費者限期內改善，經龍邑通知改善而未改善者，龍邑得依規情節輕重則限制用戶進行遊戲之權利，每次不得超過七日。 <br />
              如消費者因同一事由再次違反遊戲管理規則時，龍邑得立即依遊戲管理規則限制消費者進行遊戲之權利。 <br />
              1.於本公司提供的服務中，重覆或大量使用相近或無意義的言語影響他人使用權益者。 <br />
              2.在本公司提供的服務中，有謾罵或相互攻訐之行、言論違背公序良俗者為者足以影響他人使用權益者。 <br />
              3.在本公司提供的服務中傳送涉及辱罵、毀謗、威脅、不雅、淫穢、攻擊性等之文章、圖片；或有留言內容不符合公頻性質、於公頻刊登任何商業行為、組織或網站廣告宣傳、黃色笑話或將公佈欄當成聊天室使用之行為者。 <br />
              4.當參與任何形式的溝通 (聊天、語音聊天、團隊聊天、私聊等) 時，您需要為您表現自我的行為負責，您不能對他人使用冒犯或粗俗的言語。 <br />
              5.惡意於本公司所提供之服務內測試系統禁用字元、言論者。 <br />
              6.於本公司所提供的服務中使用含有低級、褻瀆、粗魯、辱罵、誹謗、人身攻擊、威脅、挑釁、種族與性別歧視、不雅文字、猥褻及含煽情淫穢、具有色情內容或含意與對於人體構造或器官功能的不當描述/運用 <br />
              7.於遊戲中以現金或任何有價物品買賣、交易帳號、遊戲道具、提供非官方管道儲值方式之行為與散佈者。 <br />
              8.指射或影射宗教和公眾人物的負面言論或取名或影射宗教人物和公眾人物名稱、以及因為別人的宗教進行誹謗或負面言論者。 <br />
              9.使用帶有政治色彩、或對政治相關之人事時地物名諱有所影射、不雅稱呼或謾罵的名稱。 <br />
              10.煽動種族、族群或國家之間的對立以及引發種族、族群或國家對立的相關言論。 <br />
              11.違反社會善良風俗之言詞內容或影響其他會員進行遊戲或聊天的行為。 <br />
              12.使用任何影射、及其他字碼（如其他字元、日文、韓文、簡體中文），造成輸入名稱無法識別的名稱。 <br />
              13.以龍邑與遊戲開發商或類似龍邑與遊戲開發商之名義使用本服務者。 <br />
              14.命名限制和以上規範相同，請注意適當名稱是依照玩家舉報、龍邑遊戲與原廠審核決定，而角色扮演伺服器會對使用遊戲相關名稱有更嚴格的規範。 <br />
              15.侵害他人智慧財產權或其他權利或有侵害之虞者。 <br />
              16.蓄意妨礙龍邑相關服務運作之行為者。 <br />
              17.利用申告功能對其他會員隨意誣告或使用本服務有損害他人名譽或侵害他人權利之行為者。 <br />
              18.其他有危害通信或違法之情事或之虞者。 <br />
              （四）若有事實足證用戶於本服務違反上述規則時，龍邑應於官網、遊戲登入頁或購買頁公告，並依照用戶登錄之通訊資料通知用戶。然用戶第一次違反上述第三項規定時，龍邑將通知消費者限期內改善，並按照情節輕重限制消費者之遊戲使用權利；用戶再次違反時，龍邑得依規則限制用戶進行遊戲之權利。 <br />
              （五）龍邑依規則限制消費者進行遊戲之權利，每次不得超過七日。 <br />
              （六）龍邑有權針對上述規定進行不定期查核，但此不表示龍邑有義務對會員傳送、張貼或發表之內容做任何形式或實質之審查。如經龍邑察覺或經他人申訴或舉發會員有違反上述各款之情事或之虞時，龍邑除有權逕行移除或刪除該內容外，並有權終止或暫停該會員之會員資格及各項會員服務。龍邑如因此產生任何損害或損失，並得向該會員請求賠償。 <br />
              若您發現帳號、密碼被非法使用時應立即通知龍邑行查證經確認有前述情事後，龍邑得暫停該組帳號或密碼之使用權，更換帳號或密碼予消費者，立即限制第三人就本遊戲服務之使用權利，且在限制期間內不得向用戶及第三人收取費用。 <br />
              龍邑將於暫時限制遊戲使用權利之時起，即刻以官網公告、簡訊、電子郵件、推播或其他雙方約定之方式通知前項第三人提出說明。若第三人不同意龍邑之處理方式，可以依報案程序，以司法途徑處理。如該第三人未於接獲通知時起七日內提出說明，龍邑應直接回復遭不當移轉之電磁紀錄予消費者，不能回復時可採其他雙方同意之相當補償方式，並於回復後解除對第三人之限制。但龍邑有提供免費安全裝置（例如：防盜卡; 電話鎖等）而消費者不使用或有其他可歸責於消費者之事由，龍邑不負回復或補償責任。 <br />
              消費者如有申告不實之情形致生龍邑或第三人權受損時，應負一切法律責任。 <br />
              （七）會員同意妥善保管個人之帳號及密碼，不得有與他人共用、租借他人等行為；會員並應不定期更新個人密碼，並於使用完服務後確實將帳號進行登出動作，以避免帳號遭他人使用；因共用帳號、委託第三人付費購買點數或其他不當使用衍生之糾紛時，龍邑有權不予以協助處理。 <br />
              （八）遊戲軟體、產品套件及遊戲內商品，一經下載、接受服務或啟封， <br />
              依消費者保護法第十九條第一項之適用。得於開始遊戲後七日內，以書面告知龍邑解除本契約，消費者無須說明理由及負擔任何費用。前項情形，消費者得就未使用之付費購買點數向龍邑請求退費。 <br />
              （九）遊戲歷程之保存期限、查詢方式及費用 <br />
              1.龍邑將保存消費者之個人遊戲歷程紀錄，且保存期間為60日，以供消費者查詢。 <br />
              2.您得以書面、網路，或親至龍邑之服務中心申請調閱消費者之個人遊戲歷程，且須提出與身分證明文件相符之個人資料以供查驗，然查驗之資料若涉及第三人隱私則不在此限。 <br />
              3.查詢費用為兩百元，龍邑接獲查詢申請，應提供第一項所列之消費者個人遊戲歷程，並於七日內以儲存媒介或書面、電子郵件方式提供資料。 <br />
              （十）消費者如有申告不實之情形致生龍邑或第三人權利受損時，應負一切法律責任。 <br />
              <br />
              十一、責任限制 <br />
              龍邑將依本契約之規定負有於提供本服務時，維護其自身電腦系統，符合當時科技或專業水準可合理期待之安全性。電腦系統或電磁紀錄受到破壞，或電腦系統運作異常時，採取合理之措施後儘速予以回復。 <br />
              龍邑違反前二項規定或因遊戲程式漏洞致生用戶損害時，依用戶受損害情形，負損害賠償責任。龍邑電腦系統發生上述所稱情況時，於完成修復並正常運作之前，不得向用戶收取費用。但用戶因共用帳號、私下交易買賣遊戲帳號、委託他人付費購買點數衍生與第三人間之糾紛，龍邑將不予協助處理。 <br />
              <br />
              十二、帳號與密碼之保管 <br />
              （一）甲方應知悉完成註冊程序後所取得之帳號及密碼，僅供甲方自行使用。甲方如將該組帳號、密碼以出售、轉讓或出借等任何形式交予第三人使用，因此產生之糾紛，由甲方自行負責，倘若因甲方的保管疏忽而致帳號、密碼遭他人非法使用時，乙方將不負責處理。 <br />
              （二）甲方取得帳號密碼後得依乙方提供之修改機制進行變更。乙方之人員（含客服人員、遊戲管理員）不得主動詢問甲方之密碼。乙方於本同意書終止後六十日內保留甲方之帳號及附隨於該帳號之電磁紀錄(包含但不限於遊戲歷程與儲值相關紀錄等)，六十日後乙方將進行刪除。 <br />
              （三）本同意書非因可歸責甲方之事由而終止者，甲方於前項期間內辦理續用後，有權繼續使用帳號及附隨於該帳號之電磁紀錄。 <br />
              （四）第二項期間屆滿時，甲方仍未辦理續用，乙方得刪除該帳號及附隨於該帳號之所有資料，但法令另有規定者不在此限。 <br />
              （五）任一方發現第三人非法使用甲方之帳號，或有使用安全遭異常破壞之情形時，應立即通知對方。乙方接獲甲方通知，或乙方通知甲方後，經甲方確認有前述情事，乙方得暫停該組帳號或密碼之使用權限，並更換新帳號或密碼予甲方，立即限制第三人就本遊戲服務之使用權利，且在限制期間內不得向用戶及第三人收取費用，並將相關處理方式揭載於遊戲管理規章。 <br />
              （六）龍邑將於暫時限制遊戲使用權利之時起，即刻以官網公告、簡訊、電子郵件、推播或其他雙方約定之方式通知前項第三人提出說明。若第三人不同意龍邑之處理方式，可以依報案程序，以司法途徑處理。如該第三人未於接獲通知時起七日內提出說明，龍邑應直接回復遭不當移轉之電磁紀錄予消費者，不能回復時可採其他雙方同意之相當補償方式，並於回復後解除對第三人之限制。但龍邑有提供免費安全裝置（例如：防盜卡; 電話鎖等）而消費者不使用或有其他可歸責於消費者之事由，龍邑不負回復或補償責任。 <br />
              （七）消費者如有申告不實之情形致生龍邑或第三人權受損時，應負一切法律責任。 <br />
              <br />
              十三、資料處理 <br />
              對於會員所登錄或留存之資料，龍邑得於依照個資法必要範圍內蒐集、處理、保存、傳遞及使用該等資料，以提供會員其他資訊或服務；或作成會員統計資料、進行關於網路行為之調查或研究或為任何之合法使用。 <br />
              （一）龍邑應保存會員之個人遊戲歷程紀錄，且保存期間為二(2)個月，以供會員查詢。如逾電磁紀錄保存期限二(2)個月者，恕龍邑無法受理會員之查詢申請。  <br />
              （二）會員得以書面、網路，或親至龍邑之服務中心申請調閱會員之個人遊戲歷程，且須提出與身分證明文件相符之個人資料以供查驗，查詢費用為新臺幣貳佰(200)元，由會員負擔。 <br />
              （三）龍邑接獲會員之查詢申請及查詢費用後，應提供第一項所列之會員個人遊戲歷程，並於七日內以光碟或磁片等儲存媒介或以書面、電子郵件方式提供資料。 <br />
              （四）本服務所有電磁紀錄均屬龍邑所有，並維持相關電磁紀錄之完整。 <br />
              您對於前項電磁紀錄有使用支配之權利。但不包括本遊戲服務範圍外之移轉、收益行為。 <br />
              <br />
              十四、隱私權保護 <br />
              關於您的會員註冊以及其他特定資料依「隱私權政策」受到保護與規範。您了解當您使用本服務時，您同意龍邑依據「隱私權政策」進行您個人資料的蒐集、處理與利用。 <br />
              為豐富用戶的遊戲體驗，龍邑提供的一些移動應用程式（以下簡稱“龍邑APP”）可能會需要使用者設備的某些許可權(包括但不限於：位置許可權、通訊錄許可權、照片許可權、相機許可權、儲存許可權、通知許可權、麥克風許可權、無線資料/WLAN/網路許可權、移動支付許可權、運動/健康許可權等)。通過這些設備許可權，龍邑會獲取對應的設備資訊或者使用者資料（例如：使用了諸如TrueDepth API等面部識別功能的龍邑APP會通過相機許可權獲取使用者的面部特徵資料），以下簡稱“設備資料”。龍邑對設備資料的收集、使用、披露、共用及保存將遵守《隱私權政策》對應規定，具體內容如下： <br />
              （一）資料的收集 <br />
              用戶在第一次使用龍邑APP的相關功能時會收到獲取這些設備許可權的提醒，請您仔細閱讀提醒中說明的許可權類別。使用者可以選擇關閉龍邑APP的設備許可權，這不會影響用戶繼續訪問龍邑APP，但會導致用戶無法享用龍邑APP中的某些功能。 <br />
              （二）資料的使用 <br />
              針對通過設備許可權獲得的資料（包括設備資訊及通過設備許可權獲得的使用者資料，以下簡稱“設備資料”），龍邑將僅用於向用戶提供龍邑APP中對應的功能。除《隱私權政策》規定的例外情況之外，未經使用者同意龍邑不會將設備資料用於龍邑APP之外。 <br />
              （三）資料的披露 <br />
              龍邑僅在向用戶提供龍邑APP對應功能的所必需範圍內對外披露無法識別使用者個人身份的設備資料。除《隱私權政策》規定的例外情況之外，未經使用者同意龍邑不會向實現龍邑APP對應功能無關的協力廠商披露使用者設備資料。 <br />
              （四）資料的共用 <br />
              龍邑僅在向用戶提供龍邑APP對應功能的所必需範圍內與協力廠商共用無法識別使用者個人身份的設備資料。除《隱私權政策》規定的例外情況之外，未經使用者同意龍邑不會與實現龍邑APP對應功能無關的協力廠商共用使用者設備資料。 <br />
              （五）資料的保存 <br />
              龍邑僅在向用戶提供龍邑APP對應功能的所必需時限及法律法規要求的時限內保留使用者設備資料。 <br />
              <br />
              十五、會員內容之保留 <br />
              龍邑不對於會員所傳送、張貼或發表之內容（包括但不限於文字、圖片、音樂、影像、軟體、資訊及各種資料等）進行任何形式或實質之審查，亦不擔保其正確、完整、安全或可靠。會員對於使用由其他會員所提供之內容應自行考量其風險。 <br />
              <br />
              十六 申訴權利 <br />
              （一）若您不滿意龍邑提供的連線品質、遊戲管理、費用計費、其他相關之服務品質，或對依遊戲管理規則之處置不服時，得於收到通知之翌日起七日內至服務中心或以電子郵件或書面提出申訴，乙方應於接獲申訴後，於15日內回覆處理之結果。 <br />
              （二）龍邑將於官網或遊戲管理規則中明定服務專線、電子郵件等相關聯絡資訊與二十四小時申訴管道。反映第三人利用外掛程式或其他影響遊戲公平性之申訴，依第一項規定辦理。 <br />
              <br />
              十七、智慧財產權 <br />
              龍邑上之所有著作及資料，其著作權、專利權、商標等智慧財產權、所有權或其他權利、利益，均為龍邑或原權利人所有，除事先經龍邑或原權利人之合法授權外，會員不得擅自重製、傳輸、改作、編輯或以其他任何形式、或基於任何目的使用，違者應自負相關之法律責任。 <br />
              <br />
              十八、服務終止與退費 <br />
              消費者得隨時通知龍邑終止本契約，若您要終止本契約，需要以書面方式提出並告知龍邑此一需求。 <br />
              若您超過365天未登入使用本遊戲服務，龍邑將於停止本服務之15日前通知您登入，如您屆期仍未登入使用，則終止本契約。 <br />
              有下列重大情事之一者，龍邑可依消費者登錄之通訊資料通知您，得立即終止本契約： <br />
              （一）利用任何系統或工具對龍邑電腦系統之惡意攻擊或破壞。 <br />
              （二）以利用外掛程式、病毒程式、遊戲程式漏洞或其他違反遊戲常態設定或公平合理之方式進行遊戲。 <br />
              （三）以冒名、詐騙或其他虛偽不正等方式付費購買點數或遊戲內商品。 <br />
              （四）因同一事由違反遊戲管理規則達一定次數（不得少於三次）以上，經依定型化契約第十五點第二項通知改善而未改善者。 <br />
              （五）經司法機關查獲利用遊戲帳號從事任何不法之行為。 <br />
              龍邑對前項事實認定產生錯誤或無法舉證時，將對消費者之損害負賠償責任。 <br />
              契約終止時，龍邑於扣除必要成本後，將於三十日內以現金、信用卡、匯票或掛號寄發支票方式退還消費者未使用之付費購買之點數或遊戲費用，或依雙方同意之方式處理前述點數或費用。 <br />
              如會員決定終止針對個人之服務並要求本公司進行會員未使用儲值（會員免費獲得的儲值點數不包含在內且不予退還）之清算返回，本公司將扣除手續之必要成本後，退還會員未使用的儲值或遊戲費用。 <br />
              手續之必要成本項目包含： <br />
              1.手續費：計算方式為 （會員剩餘未使用的儲值點數 – 免費贈送點數）之儲值對價 X 40%。 <br />
              2.依會員選擇之退費方式，由會員承擔因郵寄/匯票產生之費用。 <br />
              3.其他因會員辦理退費手續所延伸之個人費用。 <br />
              <br />
              十九、停止營運 <br />
              （一）龍邑會於停止本遊戲服務之營運前30日公告於官網首頁及遊戲內；若消費者於註冊帳號時已登錄通訊資料者，並依消費者登錄之通訊資料通知消費者。 <br />
              （二）若龍邑未提前公告並通知者，將退還用戶未使用之付費購買點數或遊戲費用且不會扣除必要成本。並提供其他合理之補償。 <br />
              <br />
              二十、準據法及管轄法院： <br />
              本服務條款之解釋及適用，以及會員因使用本服務而與龍邑間之權利義務關係，悉依龍邑所在地區法律及相關法令解釋適用之（不含涉外民事法律適用法或其他類似法規）。其因此所生之爭議，均同意以台北地方法院為第一審管轄法院。 <br />
              前項約定不得排除消費者保護法第四十七條及民事訴訟法第二十八條第二項、第四百三十六條之九規定之適用。 </p>
            </div>
          </div>
          <aside id="data_use" className="colorlib-breadcrumbs">
            <div className="container">
            <div className="row">
              <div className="col-md-12 breadcrumbs text-center">
              <h2>個人資料提供同意書</h2>
              <p><span>Personal Information Provision Consent</span></p>
              </div>
            </div>
            </div>
          </aside>
          <div className="content-terms">
            <div className="container">
            <p className="info"> 當您完成龍邑會員註冊(免費)後，第一次登入龍邑網站 (下稱本網站)進行商品購買時，您同意以下內容並於勾選後進行下一步。您瞭解： <br />
            一、龍邑股份有限公司 (下稱本公司)取得您的個人資料，目的在於個人資料保護法及相關法令之規定下，依本公司隱私權保護政策，蒐集、處理及利用您的個人資料。 <br />
            二、您可依您的需要提供以下個人資料：姓名、出生年月日、身分證統一編號、連絡方式(包括但不限於電話號碼、E-MAIL或居住地址)或其他得以直接或間接識別您個人之資料。 <br />
            <table className="info">
              <tbody>
              <tr>
                <th width="25%">類別</th>
                <th width="25%">資料類別</th>
                <th  width="50%">收集目的</th>
              </tr>
              <tr>
                <td>姓名</td>
                <td>C001</td>
                <td>為確保會員資料正確。</td>
              </tr>
              <tr>
                <td>出生年月日</td>
                <td>C011</td>
                <td>為確保會員資料正確。</td>
              </tr>
              <tr>
                <td>身份證字號</td>
                <td>C003</td>
                <td>為確保會員資料正確。</td>
              </tr>
              <tr>
                <td>手機號碼及電話號碼</td>
                <td>C001</td>
                <td>為確保會員資料正確及會員進階認證。</td>
              </tr>
              <tr>
                <td>電子郵件信箱</td>
                <td>C132</td>
                <td> 為確保會員資料正確及會員信箱認證，或寄發電子報。 </td>
              </tr>
              <tr>
                <td>居住地址</td>
                <td>C001</td>
                <td>為確保會員資料正確，或寄發活動實體獎品。</td>
              </tr>
              <tr>
                <td>駕駛執照</td>
                <td>C039</td>
                <td>為確保會員資料正確。</td>
              </tr>
              <tr>
                <td>信用卡或簽帳卡之號碼</td>
                <td>C002</td>
                <td>提供會員消費服務之紀錄。</td>
              </tr>
              <tr>
                <td>銀行帳戶之號碼與姓名</td>
                <td>C002</td>
                <td>提供會員消費服務之紀錄。</td>
              </tr>
              </tbody>
            </table>
            <p className="info">三、您同意本公司以您所提供的個人資料確認您的身份、與您進行連絡、提供您本公司相關服務及資訊，以及其他隱私權保護政策規範之使用方式。 <br />
              四、您同意龍邑利用您的個人資料之期間為，自即日起至您與本公司間所有契約均終止後五年止，利用地區為全球。 <br />
              五、您可依個人資料保護法之規定，就您的個人資料向本公司︰ <br />
              1.&nbsp;&nbsp;&nbsp;&nbsp;請求查詢或閱覽。 <br />
              2.&nbsp;&nbsp;&nbsp;&nbsp;製給複製本。 <br />
              3.&nbsp;&nbsp;&nbsp;&nbsp;請求補充或更正。 <br />
              4.&nbsp;&nbsp;&nbsp;&nbsp;請求停止蒐集、處理及利用。 <br />
              5.&nbsp;&nbsp;&nbsp;&nbsp;請求刪除。但因本公司執行職務或業務所必需者，本公司得拒絕之。 <br />
              六、您可自由選擇是否提供本公司您的個人資料，但若您所提供之個人資料，經檢舉或本公司發現不足以確認您的身分真實性或其他個人資料冒用、盜用、資料不實等情形，本公司有權暫時停止提供對您的服務，若有不便之處敬請見諒。 <br />
              七、您瞭解此一同意符合個人資料保護法及相關法規之要求，具有書面同意本公司蒐集、處理及利用您的個人資料之效果。 </p>
            </p>
            </div>
          </div>
          <aside id="privacy" className="colorlib-breadcrumbs">
            <div className="container">
            <div className="row">
              <div className="col-md-12 breadcrumbs text-center">
              <h2>隱私權政策</h2>
              <p><span>Privacy Policy</span></p>
              </div>
            </div>
            </div>
          </aside>
          <div className="content-terms">
            <div className="container">
            <p className="info"> 當您加入本網站會員時，建議您需先詳細閱讀、瞭解本隱私權政策。若修訂版本有實質重大更動，龍邑將會提前30日在本網站發布更新訊息，30日期間屆滿，本網站將正式施行前述之隱私權政策。 <br />
              本隱私權政策描述了龍邑如何蒐集、處理、利用您的個人資訊。 <br />
              <br />
              資訊之蒐集、處理及利用 <br />
              於本網站上的單純瀏覽及檔案下載行為，本網站並不會蒐集任何有關個人的識別資料。 <br />
              本網站在蒐集會員的個人資料後，非經您本人同意、授權或因其他法律上的規定，不會擅自將非公開資料透露給無關之第三者知悉。 <br />
              您提供給龍邑的個人資料，將使本公司得以利用並提醒您新產品或服務訊息、功能，或加強產品或服務，或寄送 ecards 或「邀請好友」電子郵件；或寄送實體發票；及/或通知您有關升級的機會、比賽、促銷，或其他特別活動及優惠。 <br />
              請注意，除法令另有規定外，本公司無法控管經本公司提供資料之第三方的行為，因此本公司無法保證他們會與龍邑一樣遵守同樣的隱私和安全程序。 <br />
              請注意，任何與龍邑網站連結的廣告商或其他網站可能會蒐集您的個人資料。因此，請您謹記本隱私權政策並不適用於那些廣告商或其他網站，也無法控制他們的行為。 <br />
              <br />
              本網站提供您關於會員資料發布之互動設定選項，您可以在選項設定之頁面，依您的需求自行選擇並設定是否公開您的姓名、電子郵件地址、性別、生日、居住地址等資料。 <br />
              網站會記錄使用者上站的IP位址、上網時間以及在網站內所瀏覽的網頁等資料，這些資料係供本網站內部作網站流量和網路行為調查的總量分析，以利於提昇本網站的服務品質，且本網站僅對全體使用者行為總和進行分析，並不會對個別使用者進行分析。 <br />
              會員依本隱私權政策有義務保護各會員隱私，非經您本人同意不會自行修改或刪除任何會員之個人資料及檔案。除非經過您事先同意或符合以下情況始得為之： <br />
              1.經由合法的途徑。  <br />
              2.保護或防衛相關網路民眾的權利或所有權。 <br />
              3.為保護本網站各相關單位之權益。 <br />
              4.配合司法單位合法的調查。 <br />
              5.配合公務機關依職務需要之調查或使用。 <br />
              6.基於善意相信揭露為法律需要，或為維護和改進網站服務而用於管理 。 <br />
              7.若您對龍邑之成員要求提供任何服務，該集團成員將會根據您的個人資料發送有關之行銷資訊。 <br />
              8.根據契約與龍邑合作商業營運之服務供應商，例如為了防止詐欺行為，為了行銷目的所為之收集資料，或提供技術服務。而這些服務供應商僅能於其服務範圍內使用您的個人資料，將不會用於其他任何商業用途。 <br />
              9.龍邑計劃合併或收購之公司。若有前開情事，龍邑將會要求新合併的實體遵循本隱私權政策，並尊重您的個人資料保密性。 <br />
              <br />
              <br />
              您申請加入本網站會員時所提供個人的識別資料，將用作龍邑進行會員驗證、數據統計及分析、獎項兌換、廣告行銷、會員服務訊息、特別活動、新服務、新產品之通知等用途。 <br />
              若您透過本網站有購物、兌換贈品，因而產生的金流、物流必要，本網站有可能會提供您前述範圍內必要的個人識別資料給相關機關或合作、協力廠商作為交易驗證或商品送達之用。 <br />
              龍邑在為您提供服務時或進行會員驗證、活動獎項頒發…等情形，龍邑可能要求您協助提供可證明個人身份之證件正本、傳真或影本，以及郵寄地址、匯款帳戶等資料。 <br />
              <br />
              Cookie <br />
              為了便利使用者的網頁瀏覽及操作，本網站可能使用cookie技術或其他類似技術，以提供更適合使用者個人需要的服務。cookie是網站伺服器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦中儲存某些資訊。使用者可以經由瀏覽器的設定，取消或限制此項功能。 <br />
              <br />
              保全 <br />
              根據本政策所指之個人資料，係指得以具體指名特定人之相關資料，並不包括以匿名方式所提供之資料。您的個人資料將會儲存在龍邑於台灣地區顧客營運中心之電腦。為保障您的隱私及安全，您的個人資料會採用合理之保全系統加以保護，例如防火牆或加密保護，防止個人資料被竊取、竄改、損毀、滅失或洩漏。龍邑僅授權有職務上必要之雇員，方得接觸您的個人資料。 <br />
              <br />
              除本隱私權政策之規定內容外，龍邑將不會出售或出租任何您的個人資料予第三方。 <br />
              <br />
              若相關法律之規定與本隱私權政策有所衝突，龍邑將遵循該法律之規定。 <br />
            </p>
            </div>
          </div>
          <div className="col-md-12 text-center"><a href="#" className="btns"><i className="icon-forward"></i> 返回</a></div>
        </div>
        <div className="section-container"> 
          <Footer active={0} />
        </div>
      </Fragment>
    );
  }
}
export default withRouter(CompleteAgreement);
