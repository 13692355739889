import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import $ from 'jquery';
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import CarouselBox from "./CarouselBox";
import TopGameBox from "./TopGameBox";
import { getCarousels, getTopGames } from "../../actions/gameActions";
import './home.css';
window.jQuery = window.$ = $;
require('flexslider'); 

class HomePage extends Component {
  componentDidMount() {
    this.props.getCarousels();
    this.props.getTopGames();

    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    this.sliderMain();
  }

  sliderMain() {
		
    $('#colorlib-hero .flexslider').flexslider({
    animation: "fade",
    slideshowSpeed: 5000,
    directionNav: true,
    start: function(){
      setTimeout(function(){
        $('.slider-text').removeClass('animated fadeInUp');
        $('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
      }, 500);
    },
    before: function(){
      setTimeout(function(){
        $('.slider-text').removeClass('animated fadeInUp');
        $('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
      }, 500);
    }

    });

  };
  
  render() {
    const { carousels, topGames } = this.props;
    let carouselBox;
    if (carousels.length === 0) {
      carouselBox = null;
    } else {
      carouselBox = carousels
        .map(game => <CarouselBox key={game.game_id} game={game} />);
    }

    let topGameBox;
    if (topGames.length === 0) {
      topGameBox = null;
    } else {
      topGameBox = topGames
        .map(game => <TopGameBox key={game.game_id} game={game} />);
    }
    
    return (
      <div id="page">
        <Navbar active={1} />    
        <aside id="colorlib-hero">
          <div className="mb-logo"><a href="/"><img src="img/e_2.png" alt="" /></a></div>           
          <div className="flexslider">
          <ul className="slides">  
            {carouselBox}
          </ul>
          </div>
        </aside>
        <aside className="colorlib-breadcrumbs">
          <div className="container">
          <div className="row">
            <div className="col-md-12 breadcrumbs text-center">
            <h2>熱門遊戲</h2>
            <p><span>TOP Games</span></p>
            </div>
          </div>
          </div>
        </aside>
        <div id="colorlib-container">
          <div className="container">
          <div className="row row-pb-md">
            {topGameBox}
          </div>
          <div className="col-md-12 text-center"><a href="/game/gamesroom/1" className="btns"><i className="icon-forward"></i>　所有遊戲</a></div>
          </div>
        </div>
        <Footer active={1} />
      </div>
    );
  }
}

HomePage.propTypes = {
  getCarousels: PropTypes.func.isRequired,
  getTopGames: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  carousels: state.games.carousels,
  topGames: state.games.topGames
});

export default connect(mapStateToProps, { getCarousels, getTopGames })(HomePage);