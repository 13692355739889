import {
  BEGIN_LOADING,
  CLEAR_LOADING,
  CONTACT_US_EMAIL
} from "../actions/types";

const initialState = {
  create_result: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONTACT_US_EMAIL:
      return {
        ...state,
        create_result: action.payload,
        loading: false
      };

    case BEGIN_LOADING:
      return {
        ...state,
        loading: true
      };
    case CLEAR_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
